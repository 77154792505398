import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import { v4 } from "uuid";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faCalendarAlt,
  faChessKnight,
} from "@fortawesome/free-solid-svg-icons";
FontAwesomeIcon.autoAddCss = false;

// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  summary,
  helmet,
  services,
  contact,
  previewContent,
}) => {
  const PageContent = HTMLContent || Content;

  let heroImage, footerImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;

  const showTitle = options.showTitle;

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="content">
              <DisplayContentHeader title={title} />
            </div>

            {previewContent ? (
              <>
                <div className="content">
                  <div className="content content___index-page">
                    {previewContent}
                  </div>
                </div>
                <div style={{ height: ".25rem" }} />
              </>
            ) : (
              <div className="content">
                <PageContent
                  className="content content___index-page"
                  content={body}
                />
                <div style={{ height: ".25rem" }} />
              </div>
            )}
            {services && services.service && services.service.length ? (
              <div className="content services">
                <h3>{services.title}</h3>

                <div className="columns">
                  {services.service.map((service) => {
                    return (
                      <React.Fragment key={v4()}>
                        <div
                          className={`column has-text-centered is-${
                            12 / services.service.length
                          }`}
                        >
                          {fontawesome(service.icon, {
                            height: "100px",
                            padding: "1rem",
                            marginTop: "2rem",
                          })}
                          <h3>{service.title}</h3>
                          <p>{service.description}</p>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <br />
              </div>
            ) : null}

            {contact ? (
              <>
                <div className="content">
                  <h3 className="mb-0 pb-0">Contact {contact.title}</h3>
                  <ul className="is-unstyled">
                    <li>
                      <span className="tel" value={contact.phone}>
                        <span className="type is-hidden">Work</span>{" "}
                        <span className="value">
                          <a
                            href={`tel:${contact.phone}`}
                            title={`Call ${contact.phone}`}
                          >
                            {contact.phone}
                          </a>
                        </span>
                      </span>
                    </li>
                    <li>{contact.hours}</li>
                    {contact.link && contact.link.title && contact.link.slug ? (
                      <>
                        <li>
                          <Link
                            to={contact.link.slug}
                            title={contact.link.title}
                          >
                            {contact.link.title}
                          </Link>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

IndexPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
  services: PropTypes.object,
  contact: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;

  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  return (
    <Layout>
      <IndexPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        services={post.frontmatter.services}
        contact={post.frontmatter.contact}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;
// b558fdc5-e049-5f19-aaf7-1d9704af5a03
export const IndexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        summary
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        services {
          title
          service {
            title
            description
            icon
          }
        }
        contact {
          title
          phone
          hours
          link {
            title
            slug
          }
        }
      }
    }
  }
`;

function fontawesome(icon, style = { height: "20px" }) {
  let results;

  switch (icon) {
    default:
    case "faCalculator":
      results = <FontAwesomeIcon icon={faCalculator} style={style} />;
      break;

    case "faCalendarAlt":
      results = <FontAwesomeIcon icon={faCalendarAlt} style={style} />;
      break;

    case "faChessKnight":
      results = <FontAwesomeIcon icon={faChessKnight} style={style} />;
      break;
  }
  return results;
}
